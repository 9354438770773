import React from "react";

const Mis = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Mis/System Architect</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Dağıtık Sistemler Yönetimi</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Esri Technologies</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">CI/CD</h3>
                            <span className="skills__level">Basic</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">CMDB & uCMDB</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>
                </div>

                <div className="skills__group">
                  

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Server İşletim</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Sistem Analiz ve Tasarımı</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Tool Bağımsız Süreç Geliştirme</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mis;