import React from "react";

const Info = () => {
    return (
        <div className="about__info grid">
            <div className="about__box">
            <i class="bx bx-award about__icon"></i>

                <h3 className="about__title">Deneyim</h3>
                <span className="about__subtitle">10 Yıldır Çalışmaktayım</span>
            </div>

            <div className="about__box">
            <i class="bx bx-briefcase-alt about__icon"></i>

                <h3 className="about__title">Çalıştığım Projeler</h3>
                <span className="about__subtitle">9+ Proje </span>
            </div>

            <div className="about__box">
            <i class="bx bx-support about__icon"></i>

                <h3 className="about__title">Destek</h3>
                <span className="about__subtitle">Online 7 / 24</span>
            </div>
        </div>
    )
}

export default Info