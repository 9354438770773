import React from "react";
import "./skills.css";
import Developer from "./Developer";
import Mis from "./Mis";

const Skills = () => {
    return (
        <section className="skills section" id="skills">
            <h2 className="section__title">Yetenekler</h2>
            <span className="section__subtitle">My techincal level</span>
        
            <div className="skills__container container grid">
                <Developer />

                <Mis />
            </div>
        </section>
    );
};

export default Skills;