import React from "react";

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://instagram.com/barisaydinofficiall?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr" className="home__social-icon" target="https://instagram.com/barisaydinofficiall?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr">
            <i class="uil uil-instagram"></i>
            </a>

            <a href="https://twitter.com/brsaydnofficial" className="home__social-icon" target="https://twitter.com/brsaydnofficial">
            <i class="uil uil-twitter"></i>
            </a>

            <a href="https://github.com/brsaydn" className="home__social-icon" target="https://github.com/brsaydn">
            <i class="uil uil-github-alt"></i>
            </a>
        </div>
    )
}

export default Social