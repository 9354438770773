import React from "react";

const Developer = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Developer</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">HTML&CSS - Bootstrapt</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Javascript&React</h3>
                            <span className="skills__level">Basic</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Signal R</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">C#</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Repository Pattern</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>
                </div>

                <div className="skills__group">
                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">N-Tier Architecture</h3>
                            <span className="skills__level">Intermedite</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">.Net Core</h3>
                            <span className="skills__level">Intermedite</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">ML.NET - Accord.NET</h3>
                            <span className="skills__level">Basic</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">SQL</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Oracle PL/SQL</h3>
                            <span className="skills__level">Advandec</span>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    );
};

export default Developer;