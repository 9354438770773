import React from "react";
import { useState } from "react";
import "./services.css";

const Services = () => {
        const [toggleState, setToggleState] = useState(0);

        const toggleTab = (index) => {
            setToggleState(index);
        }
    return (
        <section className="services section" id="services">
            <h2 className="section__title">Projelerim</h2>
            <span className="section__subtitle">Üzerinde çalıştığım projelerim</span>

            <div className="services__container container grid">
                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">Demand and Project Management
                         <br /> System</h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(1)} >View More
                    <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                            <h3 className="services__modal-title">DeProMan.Sys</h3>
                            <p className="services__modal-description">
                            Günümüz iş dünyasında başarılı olmak için verimlilik ve süreç yönetimi 
                            büyük önem taşımaktadır. İşte tam da bu noktada, talepleri 
                            ve projeleri etkili bir şekilde yönetebilmenizi sağlayacak yenilikçi bir 
                            yönetim sistemi olarak ortaya çıkacak "DeProMan.Sys" geliştirmeye devam ediyorum.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Müşteri taleplerini kolayca kaydedin ve sınıflandırın.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                    AI destekli talepleri önceliklendirme ve atama yetenekleriyle hızlı bir şekilde işleyin.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                    Proje yaşam döngüsünü endüstri standartlarına uygun bir şekilde yönetin.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                    Proje içindeki görevleri belirleyin ve ekipler arasında etkili bir şekilde dağıtın. İlerleme durumunu anlık olarak takip edin.
                                    </p>
                                </li>
                                
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                    Görevlerin tamamlanma durumunu anlık olarak izleyerek, Verimlilik ve performansı ölçmek için kapsamlı raporlamalar sağlar.
                                    </p>
                                </li>
                               
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                    Mobil cihazlarla uyumlu, her yerden erişim imkanı.
                                    </p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title">Drawing Conclusions from Big Data<br />  Engine</h3>
                    </div>
                    <span onClick={() => toggleTab(2)} className="services__button">View More 
                    <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                            <h3 className="services__modal-title">LaplacE</h3>
                            <p className="services__modal-description">
                            "Sonuç Çıkarma Motoru" ifadesi, yönetim sistemleri, veritabanları veya iş süreçleri içinde kararlar almak, 
                            stratejik planlamalar yapmak ve performans analizleri yapmak amacıyla kullanılan bir araç 
                            veya mekanizma olarak tanımlanabilir. "LaplacE" C# AI kütüphaneleri olan ML.NET - Accord.NET ve veritabanlarından
                            anlık veri okuması sağlayan SignalR teknolojileri ile veriyi okuyan, işleyen ve belirlenen hedefler doğrultusunda
                            bir sonuç döndüren sistem olarak geliştirilmesi hedeflenmektedir.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Datayı belirlenen şirket kuralları çerçevesinde anlamlı veriye dönüştürmek.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Anlık veri okuma ve raporlama sayesinde hızlı karar alınmasının sağlanması.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Local ve bulut veri kaynaklarından aynı anda veri okuma ve birleştirme.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Önceden çıkan sonuçların karara dönüşmesi devamında başarılı olduğunun takibi ve ileriki sonuçlarda daha doğru karar verilmesinin sağlanması.
                                    </p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-edit services__icon"></i>
                        <h3 className="services__title">GIS Supported 
                        <br />Business Management System</h3>
                    </div>

                    <span onClick={() => toggleTab(3)} className="services__button">View More 
                    <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                            <h3 className="services__modal-title">GisBMS</h3>
                            <p className="services__modal-description">
                            Coğrafi Bilgi Sistemi Destekli İşletme Yönetim Sistemi.C# tabanlı ve Esri 
                            .Net SDK teknolojisi ile geliştirmeyi planladığım bir uygulama. Dağınık işletme
                            yapısının tek bir sistem üzerinde toplanarak izlenmesi, planlanması ve yönetilmesi
                            hedeflenmektedir.   
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Üretim ve Stok takibi.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        İşletme envanterinin konumsal olarak tutulması, izlenmesi ve raporlanması.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Dış uygulama entegrasyonları ile üretim, stok, tedarik zincirinin tek ekrandan takibi.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        v2 planlamasında konum tabanlı lojistik takibi.(Hangi tıra depodan nekadar ürün yüklendi, nereye gidiyor,vs gibi izleme özelliği.)
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        İşletme fonksiyonları tabanlı analiz ve raporlamalar.
                                    </p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        
        
        </section>
    );
};

export default Services;